<template>
  <div id="emptyFileModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Xoá ruột file nhạc</h3>
        <button class="emptyFileClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <form v-on:submit.prevent="emptyFile">
        <div class="px-5 py-2 text-center">
          Bạn có chắc chắn muốn xoá ruột file nhạc này?
        </div>

        <div class="flex items-center w-full justify-center border-t p-3">
          <button type="submit"
            class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
            Thực hiện</button>
        </div>
      </form>
     
    </div>
  </div>
</template>

<script>

import ApiService from "../core/services/api.service";

export default {
  name: "EmptyFileModal",
  props: {
    id: String,
    type: String
  },
  methods: {
    emptyFile() {
      let query = `mutation($id: ID!, $type: String!) {
        emptyFile(id: $id, type: $type) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.id, type: this.type})
          .then(({data}) => {
            if (data.data && data.data.emptyFile) {
              this.$emit("emptyFileDone");
              window.$(".emptyFileClose").click();
              this.$toast.success("Cập nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    }
  }
}
</script>
