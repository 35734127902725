<template>
  <div id="createSheetRevisionModal" class="create-post" uk-modal>
    <div
      class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
    >
      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Tạo phiên bản sheet nhạc mới</h3>
        <button
          class="createSheetRevisionClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
          type="button"
          uk-close
          uk-tooltip="title: Close ; pos: bottom ;offset:7"
        ></button>
      </div>

      <div class="px-5 py-2">
        <editor
          :full="false"
          id="content"
          v-model:content="sheetRevision.content"
        />
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a
          href="javascript:void(0)"
          v-on:click="createSheetRevision"
          class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold"
        >
          Tạo mới</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../core/services/api.service";
import Editor from "./Editor";

export default {
  name: "CreateSheetRevisionModal",
  props: {
    revision: Object,
  },
  components: { Editor },
  data() {
    return {
      sheetRevision: {
        title: "",
        content: "",
        sheet_id: "",
      },
    };
  },
  methods: {
    createSheetRevision() {
      let query = `mutation($title: String, $content: String,  $sheet_id: ID!) {
        createSheetRevision(input: {
          title: $title
          content: $content
          sheet_id: $sheet_id
        }) {
          id
        }
      }`;

      ApiService.graphql(query, {
        id: this.sheetRevision.id,
        title: this.sheetRevision.title,
        content: this.sheetRevision.content,
        sheet_id: this.sheetRevision.sheet_id,
      })
        .then(({ data }) => {
          if (data.data && data.data.createSheetRevision) {
            this.$emit("createSheetRevisionDone");
            window.$(".createSheetRevisionClose").click();
            this.$toast.success("Tạo mới thành công");
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
  },
  watch: {
    revision: {
      handler(newVal) {
        this.sheetRevision.title = newVal.title;
        this.sheetRevision.sheet_id = newVal.id;
      },
      deep: true
    },
  }
};
</script>
