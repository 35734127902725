<template>
  <div id="updateSheetRevisionModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Cập nhật phiên bản sheet nhạc<span v-if="revision && revision.uploader"> - bởi {{ revision.uploader.username }}</span></h3>
        <button class="updateSheetRevisionClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2">
        <editor :full="false" id="content" v-model:content="sheetRevision.content" />
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="updateSheetRevision"
           class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Cập nhật</a>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../core/services/api.service";
import Editor from "./Editor";

export default {
  name: "updateSheetRevisionModal",
  props: {
    revision: Object,
  },
  components: {Editor},
  data() {
    return {
      sheetRevision: {
        id: null,
        content: ""
      },
    }
  },
  methods: {
    updateSheetRevision() {
      let query = `mutation($id: ID!, $content: String) {
        updateSheetRevision(input: {
          id: $id,
          content: $content
        }) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.sheetRevision.id, content: this.sheetRevision.content})
          .then(({data}) => {
            if (data.data && data.data.updateSheetRevision) {
              this.$emit("updateSheetRevisionDone");
              window.$(".updateSheetRevisionClose").click();
              this.$toast.success("Cập nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
  },
  watch: {
    revision: {
      handler(newVal) {
        this.sheetRevision = newVal;
      },
      deep: true
    },
  }
}
</script>
