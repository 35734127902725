<template>
  <div id="deleteSheetRevisionModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Xoá phiên bản sheet nhạc</h3>
        <button class="deleteSheetRevisionClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2 text-center">
        <p v-if="revision && revision.uploader">Bạn có chắc muốn xoá phiên bản sheet nhạc tạo bởi <strong>{{ revision.uploader.username }}</strong>?</p>
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="deleteSheetRevision"
           class="text-white hover:text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-cente">
          Xoá</a>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../core/services/api.service";

export default {
  name: "deleteSheetRevisionModal",
  props: {
    revision: Object,
  },
  data() {
    return {
      sheetRevision: {
        id: null,
      },
    }
  },
  methods: {
    deleteSheetRevision() {
      let query = `mutation($id: ID!) {
        deleteSheetRevision(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.sheetRevision.id})
          .then(({data}) => {
            if (data.data && data.data.deleteSheetRevision) {
              this.$emit("deleteSheetRevisionDone");
              window.$(".deleteSheetRevisionClose").click();
              this.$toast.success("Xoá thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
  },
  watch: {
    revision: {
      handler(newVal) {
        this.sheetRevision = newVal;
      },
      deep: true
    },
  }
}
</script>
